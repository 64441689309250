div#downloadApp .downloadFooter {
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: space-evenly;
  margin: 18px 0;
  flex-wrap: wrap;
}

div#downloadApp p {
  margin: 13px 0;
  font-size: 12px;
  padding: 0 80px;
  color: white !important;
}
div#downloadApp {
  width: min(800px, 90%);
  margin: 20px auto 30px;
  text-align: center;
  padding: 20px;
  color: white;
  background: var(--main-color);
  border-radius: 9px;
  z-index: 12;
  top: 100px;
}
@media (max-width: 767px) {
  div#downloadApp p {
    padding: 0 10px !important;
  }

  div#downloadApp {
    padding: 20px 10px !important;
  }
}
