.flashunit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--second-color);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.flashunit .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flashunit .left>p {
  background-color: rgba(217, 217, 217, 1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--main-color);
}

.flashunit .det h4 {
  font-size: 13px;
  margin-bottom: 5px;
}

.flashunit .det p {
  color: rgba(130, 130, 130, 1);
  font-size: 13px;
}