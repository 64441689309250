  .topic {
    background-color: var(--second-color);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .topic .left {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .topic .left>div>* {
    margin: 0px;
  }

  .topic .left h5 {
    background-color: var(--third-color);
    width: 40px;
    height: 40px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--main-color);
    margin: 0px;
  }

  .topic .left>div h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .topic .left>div p {
    color: #828282;
    font-size: 12px;
  }


  .topics_project_page .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .topics_project_page .text {
    margin-bottom: 0px !important;
  }

  .topics_project_page .text p {
    margin-bottom: 0px;
  }

  .topics_project_page .content {
    margin-top: 10px !important;
  }

  .topics_project_page .header>svg {
    cursor: pointer;
  }

  .topics_project_page .header>div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .topics_project_page .header svg {
    color: var(--main-color);
  }

  .topics_project_page .header h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .topics_project_page .header>div h4 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--main-color);
    text-align: left;

  }

  .topics_project_page .header>div svg {
    font-size: 22px;
  }

  .topic_compo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgba(236, 236, 236, 1);
  }

  .topic_compo .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .topic_compo .left>p {
    color: var(--main-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(217, 217, 217, 1);
  }

  .topic_compo .left .details>* {
    margin: 0px;
  }

  .topic_compo .left>* {
    margin: 0px;
  }

  .topic_compo .left .details h5 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
  }

  .topic_compo .left .details p {
    color: rgba(130, 130, 130, 1);
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

  }

  .topics_project_page .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .topics_project_page .text {
    margin-bottom: 0px !important;
  }

  .topics_project_page .text p {
    margin-bottom: 0px;
  }

  .topics_project_page .content {
    margin-top: 10px !important;
  }

  .topics_project_page .header>svg {
    cursor: pointer;
  }

  .topics_project_page .header>div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .topics_project_page .header svg {
    color: var(--main-color);
  }

  .topics_project_page .header h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .topics_project_page .header>div h4 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--main-color);
    text-align: left;

  }

  .topics_project_page .header>div svg {
    font-size: 22px;
  }

  .topic_compo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgba(236, 236, 236, 1);
  }

  .topic_compo .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .topic_compo .left>p {
    color: var(--main-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(217, 217, 217, 1);
  }

  .topic_compo .left .details>* {
    margin: 0px;
  }

  .topic_compo .left>* {
    margin: 0px;
  }

  .topic_compo .left .details h5 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
  }

  .topic_compo .left .details p {
    color: rgba(130, 130, 130, 1);
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

  }