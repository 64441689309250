.flastopics {
  padding: 10px;
}

.flastopics>div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flastopics>div:first-of-type svg {
  color: var(--main-color);
}

.flastopics>div:first-of-type>div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: var(--main-color);
}

.flastopics>p {
  margin: 10px 0px;
  color: #828282;
  font-size: 13px;
}

.flashtopics {
  margin: 10px 0px;
}