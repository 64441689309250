  .question {
    padding: 10px;
  }

  .question h4 {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
  }

  .answers .answer {
    font-size: 13px;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .answer>p {
    border: 1px solid #ccc;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .answer>p span {
    background-color: transparent;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .answer>p span.active {
    background-color: var(--main-color);
  }

  .answer span.showtrue {
    color: green;
  }

  .answer span.showfalse {
    color: var(--main-color);
  }

  /* .answer  */
