  .question_answered {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0px;
    padding: 10px;
    gap: 10px;
  }

  .question_answered .right {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .question_answered .right>div {
    flex: 1;
    background-color: var(--second-color);
    height: 8px;
    border-radius: 10px;
  }

  .question_answered img {
    width: 25px;
  }

  .sub_div .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_div .btn>button {
    background-color: var(--main-color);
    color: white;
    padding: 15px;
    border: none;
    border-radius: 10px;
    width: 200px;
    max-width: 100%;
    min-width: fit-content;
    cursor: pointer;
  }

  .explanations {
    padding: 10px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .actions button {
    width: 100px;
    max-width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .actions button:first-of-type {
    background-color: transparent;
    color: var(--main-color);
    border-color: var(--main-color);
  }

  .actions button:nth-of-type(2) {
    background-color: var(--main-color);
    color: white;
  }
