  .explanation {
    padding: 10px;
    background-color: #eb849c;

    margin: 10px 0px;
    border-radius: 10px;
    color: #111A2C;
  }

  .explanation>h4 {
    font-size: 16px;
  }

  .explanation p {
    color: #111A2C;
  }

  .explanation.true {
    background-color: #00B112;
  }

  /* .true */