.faq-row-wrapper {
  width: min(1000px, 90%);
  margin: 10px auto;
  padding: 10px;
}

h2.faq-row-wrapper h2 {
  color: var(--main-color) !important;
}

div.row-title,
.arrow-image {
  color: var(--main-color) !important;
}

section.faq-title > h2,
section.faq-title svg {
  color: var(--main-color) !important;
  font-weight: 900 !important;
}

section.faq-title > h2 {
  color: var(--main-color) !important;
  font-size: 39px;
  font-weight: 900;

  margin: auto;
}

div.row-title > span > div > svg {
  color: var(--main-color) !important;
}

section.faq-body > section {
  border-color: var(--secondary-color) !important;
}
