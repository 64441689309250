.tweet-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  max-width: 600px;
  /* margin: auto; */
  box-sizing: border-box;
}

.user-profile-img {
  width: 100%;
  /* height: 50px; */
  border-radius: 50%;
  /* margin-right: 10px; */
  /* object-fit: cover; */
}

.user-profile-img img {
  width: 80%;
}

.tweet-content {
  margin-bottom: 15px;
}

.engagement-options {
  display: flex;
  justify-content: space-between;
  color: #888;
}

.engagement-options i {
  margin-right: 5px;
}
