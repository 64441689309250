.coursedata {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--second-color);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.coursedata .left {
  width: 100px;
}

.coursedata .left img {
  max-width: 100%;
  border-radius: 10px;
}

.coursedata .right h5 {
  background-color: rgba(217, 217, 217, 1);
  color: var(--main-color);
  width: 100px;
  max-width: 100%;
  padding: 6px 10px;
  border-radius: 10px;
  text-align: center;
  text-transform: capitalize;
}

.coursedata .right h4 {
  font-size: 15px;
}

.coursedata .right p {
  font-size: 14px;
  color: #828282;
}