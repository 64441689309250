body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.device.make_padding {
  padding: 15px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.header .left {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.device .header .left h4 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
}

p {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(130, 130, 130, 1);
  margin-bottom: 20px;
}

.device .content {
  margin-top: 30px;
}

.units_page {
  padding: 10px !important;
}

.units_page .header .left {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.units_page_unit .header svg {
  color: var(--main-color);
  cursor: pointer;
}

.header a svg {
  color: white !important;
}
.device .unit_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(236, 236, 236, 1);
}

.unit_com .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.unit_com .left > * {
  margin: 0px;
}

.unit_com .left .details > * {
  margin: 0px;
}

.unit_com .left > p {
  color: var(--main-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(217, 217, 217, 1);
}

.unit_com .left h4 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.unit_com .left p {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.header + p {
  margin-bottom: 10px;
}

.device .content {
  margin-top: 10px !important;
}

.courses_typs {
  margin-bottom: 20px;
  background-color: rgba(236, 236, 236, 1);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.courses_typs .course_type {
  border-bottom: 1px solid transparent;
  padding: 4px;
  color: rgba(130, 130, 130, 1);
  cursor: pointer;
}

.courses_typs .course_type.active {
  border-color: var(--main-color);
  color: var(--main-color);
}

.courses_typs {
  width: 90%;
  margin: auto;
}

.css-1c2fuzs {
  z-index: 12232376646344 !important;
}
