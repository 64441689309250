/* .secondpage {
  padding: 15px;
} */

.secondpage>h3 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.secondpage>h3>* {
  margin: 0px;
}

.secondpage>p {
  font-size: 13px;
  color: #828282;
  margin: 10px 0px;
}