.tweetstopics {
  padding: 15px;
}

.tweetstopics>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tweetstopics>div svg {
  color: var(--main-color);
  cursor: pointer;
}

.tweetstopics>div:first-of-type>div:first-of-type {
  display: flex;
  align-items: center;
  color: var(--main-color);
}

.tweetstopics>p {
  margin: 10px 0px;
  color: #828282;
  font-size: 13px;
}