.modal_overlay {
  position: absolute;
  background: #00000096;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
}

.header_text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.C_modal {
  /* position: fixed; */
  top: 20%;
  left: 50%;
  translate: -50% -20%;
  box-shadow: 1px 2px 24px -2px #80808066;
  border-radius: 6px;
  padding: 20px;
  z-index: 99999999999999999;
  background: white;
  width: min(500px, 100%);
  display: flex;
  flex-direction: column;
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin-bottom: 15px;
}

.model_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  z-index: 99999999999999999;
}

.modal_body > * {
  width: 100%;
  white-space: pre-line;
}

.modal_header {
  width: 100%;
  padding: 20px;
  border-bottom: 0.1px solid;
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header_text span {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  font-weight: 600;
}

.modal_body {
  padding: 6px 20px;
  width: 100%;
  height: 100%;
}
