.features video {
  height: 100%;
  max-width: 100%;
}

.features {
  display: flex;
  flex-direction: column;
}

.features .banner_content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: fit-content;
  margin: 90px 0;
  justify-content: space-between;
  margin: auto;
  border-radius: 20px;
  padding: 5px 40px 20px !important;
}

.features .banner_content > div:first-child {
  width: min(550px, 100%);
  z-index: 123;
}

.video_overLay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #00000080;
  z-index: 12332;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.device {
  padding: 0px !important;
}

.device,
.slideFeatures > div {
  aspect-ratio: 260/540;
  background-color: #fff;
  border: 0.7rem solid #000;
  border-radius: 2rem;
  overflow: auto;
  overflow-x: hidden;
  padding: 10px;
  position: relative;
  display: flex;
  width: 18rem;
  flex-direction: column;
}

.question_answer_component ul li {
  display: flex;
  align-items: center;
  gap: 4px;
}

.question_answer_component {
}

.question_answer_component ul {
  list-style: none;
  padding: 4px;
}

.question_answer_component {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.question_answer_component .btns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto !important;
  align-items: center;
}

.question_answer_component .btns button.btn {
  border: 0.1px solid var(--secondary-color);
  border-radius: 100px !important;
  padding: 4px 20px;
}

.question_answer_component .btns button.btn-success {
  background-color: var(--main-color) !important;

  border: none !important;
}

.question_answer_component ul li {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 0.1px solid;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: 9px;
}

.question_answer_component ul li.checked input {
  appearance: none;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 20px;
  background: white;
}

.question_answer_component ul li.checked {
  background: var(--main-color);
  color: white;
}

.question_answer_component ul li {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 0.1px solid;
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: 9px;
}

.finishmodal {
  display: flex;
  justify-content: flex-end;
  color: green !important;
}

.features > h1 {
  margin: auto;
  text-align: center;
}

.mcq-features-section {
  text-align: center;
  padding: 50px 20px;
}

.section-header h2 {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 20px;
}

.illustration img {
  height: 200px;

  margin: 10px 0;
}

.feature-icons {
  margin: 20px 0;
}

.feature-icons i {
  font-size: 36px;
  color: #333;
  margin: 0 20px;
}

.text-description p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.sample-question {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.cta-button a {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  text-decoration: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button a:hover {
  background-color: #0056b3;
}

.feature-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.feature-icons svg {
  font-size: 48px;
  color: #007bff;
  margin: 0 20px;
  transition: color 0.3s ease;
}

.feature-icons svg:hover {
  color: #0056b3;
}

.question_answer_component .btns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto !important;
  align-items: center;
  position: sticky;
  bottom: 0;
}

.features > .slideFeatures {
  /* width: fit-content !important; */
  position: sticky;
  top: 18%;
  z-index: 287773;
  margin-left: auto;
  margin-right: 20px;
  height: 0;
  /* background-color: white; */
  display: flex;
  justify-content: center;
}

.slideFeatures > div {
  height: 36em;
}

.childs {
  /* overflow: hidden; */
}

.right_banner img:last-child {
  position: absolute;
  rotate: -20deg;
  width: 85%;
  bottom: 0;
  left: -10px;
  /* z-index: -1; */
}

.slideFeatures {
  width: fit-content;
}

.fixedMobile {
  position: fixed;
  z-index: 234552;
}

/* style.css */

.features {
  position: relative;
}

.fixedDiv {
  position: fixed;
  top: 7em;
  left: 47%;
  translate: 50%;
  width: fit-content;
  z-index: 234552;
  /* width: 100%; */
  margin: auto;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  /* background-color: #f0f0f0; */
  /* border-bottom: 2px solid #ddd; */
  /* z-index: 1000; */
}

/* Add a class to highlight the active component */
.activeComponent {
  border-bottom: 2px solid #007bff;
  /* Change the border color for the active component */
}

.visibility {
  visibility: hidden;
}

@media (max-width: 1110px) {
  .fixedDiv {
    display: none;
  }

  .features .banner_content {
    justify-content: center !important;
  }

  .visibility {
    visibility: visible;
  }
}
