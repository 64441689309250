.tweetscourses>h3 {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
}


.tweetscourses>p {
  color: #828282;
  font-size: 13px;
  padding: 10px;
}

.coursestweets {
  padding: 10px;
}