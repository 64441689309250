.card-flid {
  background-color: transparent;
  width: 90%;
  margin: 10px auto;
  height: 146px;
  perspective: 1000px;
  transform: scale(1);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.8s;
  transform-style: preserve-3d;
  /* transform-origin: 100% bottom; */
}

.card-flid.back .card-inner,
.card-flid:hover .card-inner {
  transform: rotateX(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 130px;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../../../Assets/images/homecard.png"),
    linear-gradient(to top, #ea4a5f, #ea4a5f);
  background-size: cover;
  background-position: top;
  font-size: 12.5px;
  font-weight: 700;
  line-break: anywhere;
  padding: 10px;
}

.card-back {
  background: url("../../../../../Assets/images/homecard.png"),
    linear-gradient(to top, white, white);
  color: #ea4a5f;
  background-size: cover;
  background-position: top;
}

.card-front p {
  padding-left: 20px;
  padding-top: 7px;
  font-size: 30px;
  font-weight: 500;
}

.card-back {
  backface-visibility: hidden;
  transform: rotateX(180deg);
}

.flash-cards-section {
  text-align: center;
  padding: 50px 20px;
}

.card-flid {
  background-color: transparent;
  width: 90%;
  margin: 10px auto;
  height: 146px;
  perspective: 1000px;
  transform: scale(1);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.8s;
  transform-style: preserve-3d;
  /* transform-origin: 100% bottom; */
}

.card-flid.back .card-inner,
.card-flid:hover .card-inner {
  transform: rotateX(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 130px;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../../../Assets/images/homecard.png"),
    linear-gradient(to top, #ea4a5f, #ea4a5f);
  background-size: cover;
  background-position: top;
  font-size: 12.5px;
  font-weight: 700;
  line-break: anywhere;
  padding: 10px;
}

.card-back {
  background: url("../../../../../Assets/images/homecard.png"),
    linear-gradient(to top, white, white);
  color: #ea4a5f;
  background-size: cover;
  background-position: top;
}

.card-front p {
  padding-left: 20px;
  padding-top: 7px;
  font-size: 30px;
  font-weight: 500;
}

.card-back {
  backface-visibility: hidden;
  transform: rotateX(180deg);
}

.flashcardsbtns {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flashcardsbtns button {
  padding: 10px;
  width: 100px;
  max-width: 100%;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent;
}

.flashcardsbtns button:first-of-type {
  background-color: transparent;
  color: var(--main-color);
  border: 2px solid var(--main-color);
}

.flashcardsbtns button:nth-child(2) {
  color: white;
  background-color: var(--main-color);
}

.flash_rate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
}

.flash_rate > div:nth-child(2) {
  flex: 1;
  background-color: var(--second-color);
  height: 10px;
  border-radius: 10px;
}
