.topBar {
  background-color: #000;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-size: 12px;
  justify-content: space-between;
  padding: 4px 10px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 23342343212;
  top: 0;
}
.wifiIcon,
.networkIcon {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
}

.topBar svg {
  width: 18px;
}

.networkIcon span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stickyTop {
  /* margin-bottom: auto; */
  background: black;
  top: 0;
  position: sticky;
  z-index: 12;
}
