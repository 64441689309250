.courses {
  padding: 15px;
}

.device {
  z-index: 12344254343 !important;
}

.courses>h3 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.courses>h3>span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.courses>h4 {
  color: #828282;
  font-size: 14px;
  margin: 20px 0px;
}

.courses .select_types {
  display: flex;
  align-items: center;
  background-color: #dedede;
  padding: 10px;
  border-radius: 10px;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.courses .select_types .select_type {
  border-bottom: 1px solid transparent;
  padding-bottom: 3px;
}

.courses .select_types .select_type.active {
  color: var(--main-color);
  border-color: var(--main-color);
}

.courses_comp .course {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--second-color);
  margin-bottom: 10px;
  flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 15px;
  padding: 20px 10px 20px;
  cursor: pointer;
}

.course .left {
  width: 60px;
  height: 60px;
}

.course .left img {
  max-width: 100%;
  border-radius: 10px;
  height: 100%;
}

.course .right {
  width: calc(100% - 130px);
  flex: 1;
}

.course .right>* {
  margin: 0px;
}

.course .right h5 {
  font-size: 10px;
  color: var(--main-color);
  background-color: #dedede;
  width: fit-content;
  padding: 8px;
  border-radius: 10px;
  width: 70px;
  text-align: center;
}

.course .right h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 4px 0px;
}

.projct_page.make_padding {
  padding: 15px;
}

.projct_page .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.projct_page .header .left {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.projct_page .header .left h4 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
}

.projct_page p {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(130, 130, 130, 1);
  margin-bottom: 20px;
}

.projct_page .content {
  margin-top: 30px;
}

.projct_page .units_page_unit .header svg {
  color: var(--main-color);
  cursor: pointer;
}

.projct_page .unit_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(236, 236, 236, 1);
}

.unit_com .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.unit_com .left>* {
  margin: 0px;
}

.projct_page .unit_com .left .details>* {
  margin: 0px;
}

.projct_page .unit_com .left>p {
  color: var(--main-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(217, 217, 217, 1);
}

.projct_page .unit_com .left h4 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.projct_page .unit_com .left p {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.header+p {
  margin-bottom: 10px;
}

.projct_page .content {
  margin-top: 10px !important;
}

.courses_typs {
  margin-bottom: 20px;
  background-color: rgba(236, 236, 236, 1);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.courses_typs .course_type {
  border-bottom: 1px solid transparent;
  padding: 4px;
  color: rgba(130, 130, 130, 1);
  cursor: pointer;
}

.courses_typs .course_type.active {
  border-color: var(--main-color);
  color: var(--main-color);
}