@media (max-width:18rem) {
  .flashcards {}

  .flashcards {
    padding: 15px;
  }

  .flashcards>h3 {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .flashcards>h3>* {
    margin: 0px;
  }

  .flashcards>p {
    font-size: 13px;
    color: #828282;
    margin: 10px 0px;
  }
}