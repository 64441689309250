.flashunits_page {
  padding: 10px;
}

.flashunits_page>p {
  font-size: 14px;
  color: #828282;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.flashunits_page>p svg {
  cursor: pointer;
}