.tweetsunits {
  padding: 10px;
}

.tweetsunits>h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.tweetsunits>h3 svg {
  cursor: pointer;
  color: var(--main-color);
}

.tweetsunits p {
  margin: 20px 0px;
  color: #828282;
  font-size: 14px;
}