.unit {
  background-color: var(--second-color);
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px 10px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.unit .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.unit .left h4 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  /* font-size: 3px; */
}

.unit .left>p {
  background-color: rgba(217, 217, 217, 1);
  color: var(--main-color);
  width: 40px;
  height: 40px;
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.unit .left .det>* {
  margin: 0px;
}