.slider_screen img {
  width: 100%;
}

.slider_screen {
  width: 250px;
  margin: 20px;
}

.styles-module_item-container__a8zaY {
  width: 260px !important;
  margin: 10px;
}

.styles-module_carousel-base__3keqD {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  outline: none;
  position: relative;
  align-items: center;
}
.silder_component > div {
  /* margin: auto; */
  /* width: 280px; */
  /* justify-content: center; */
  /* overflow: visible; */
}

.silder_component > div img {
  /* width: 230px; */
}

.swiper-slide,
.slider_screen {
  /* width: 280px !important; */
}

.screens_content {
  display: flex;
  flex-direction: column;
}

.breadCrumb {
  margin: 30px auto 20px;
  padding: 10px 40px;
  text-align: center;
  border-bottom: 4px solid var(--main-color);
  border-radius: 3px;
  width: min(600px, 100%);
}

.breadCrumb h1 {
  font-weight: 900;
  font-size: 52px;
  color: var(--main-color);
}

.silder_component {
  width: var(--min-width);
  margin: auto;
}

.swiper-3d .swiper-slide-shadow {
  background: none !important;
}

.swiper-pagination-bullet {
  transition: 0.6s ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 30px !important;
  border-radius: 10px !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -7px !important;
}
