.unit_com {
  background-color: var(--second-color);
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 15px 10px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.unit_com .left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.unit_com .left > div > * {
  margin: 0px;
}

.unit_com .left h5 {
  background-color: var(--third-color);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--main-color);
  flex-wrap: wrap;
  margin: 0px;
}

.unit_com .left > div h3 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.unit_com .left > div p {
  color: #828282;
  font-size: 12px;
}
