.bottom-bar {
  background: #000;
  border-radius: 19px;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  left: 0;
  padding: 10px;
  position: sticky;
  width: 100%;
  z-index: 1212;
  margin-top: auto;
}

.bottom-bar a svg {
  width: 19px !important;
  cursor: pointer;
}

.features video {
  height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.stickyBottom {
  margin-top: auto;
  background: black;
  bottom: 0;
  position: sticky;
}
