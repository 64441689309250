@media(max-width:18rem) {
  .units_page {
    padding: 15px;
  }

  .units_page>h3 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 17px;
    font-weight: 400;
  }

  .units_page>h3 svg {
    color: var(--main-color);
    cursor: pointer;
  }

  .units_page>h4 {
    color: #929292;
    font-size: 16px;
    margin: 20px 0px;
  }

  .units_page .units {
    margin: 20px 0px;
  }
}