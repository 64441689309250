.card-flid {
  background-color: transparent;
  width: 90%;
  margin: 10px auto;
  height: 146px;
  perspective: 1000px;
  transform: scale(1);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.8s;
  transform-style: preserve-3d;
  /* transform-origin: 100% bottom; */
}

.card-flid.back .card-inner,
.card-flid:hover .card-inner {
  transform: rotateX(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 130px;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../Assets/images/homecard.png"),
    linear-gradient(to top, #ea4a5f, #ea4a5f);
  background-size: cover;
  background-position: top;
  font-size: 12.5px;
  font-weight: 700;
  line-break: anywhere;
  padding: 10px;
}
.card-back {
  background: url("../../../Assets/images/homecard.png"),
    linear-gradient(to top, white, white);
  color: #ea4a5f;
  background-size: cover;
  background-position: top;
}

.card-front p {
  padding-left: 20px;
  padding-top: 7px;
  font-size: 30px;
  font-weight: 500;
}

.card-back {
  backface-visibility: hidden;
  transform: rotateX(180deg);
}

.flash-cards-section {
  text-align: center;
  padding: 50px 20px;
}

.section-header h2 {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 20px;
}

.illustration img {
  /* width: 80%; */
  max-width: 400px;
  margin: 20px 0;
}

.feature-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.feature-icons i {
  font-size: 48px;
  color: #007bff;
  margin: 0 20px;
  transition: color 0.3s ease;
}

.feature-icons i:hover {
  color: #0056b3;
}

.text-description p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.sample-flash-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.sample-flash-card p {
  font-size: 18px;
  margin-bottom: 10px;
}

.cta-button a {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  text-decoration: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button a:hover {
  background-color: #0056b3;
}

.courseName {
  padding: 10px;
  min-height: 120px;
  position: sticky;
  background: var(--main-color);
  border-radius: 10px;
  width: 100%;
  color: white;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 221;
}

.question_answer_component .btns.flashcardsbtns {
  margin: 5px !important;
}
