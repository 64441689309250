.topics_project_page {
  padding: 25px 10px;
}

.alltopics_page>div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  flex-wrap: wrap;
}

.alltopics_page>div:first-of-type .left>*,
.alltopics_page>div:first-of-type .right>* {
  margin: 0px;
}

.alltopics_page>div:first-of-type .left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}

.alltopics_page>div:first-of-type .left h5 {
  font-size: 13px;
  font-weight: 600;
}

.alltopics_page>div:first-of-type .left svg {
  color: var(--main-color);
}

.alltopics_page>div:first-of-type .right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}

.alltopics_page>div:first-of-type .right {
  color: var(--main-color);
  font-size: 14px;
}

.alltopics_page>h4 {
  font-size: 14px;
  color: #828282;
}

.alltopics_page .topics {
  margin: 10px 0px;
}

.alltopics_page>div h4 {
  font-size: 14px !important;
}