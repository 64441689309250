.leftOpinion img {
  width: 180px;
  border-radius: 9px;
  margin: 10px 0;
}

.leftOpinion {
  display: flex;
  flex-direction: column;
}
.opinion {
  background: url("../../Assets/images/left-quote.png");
  background-position: 80%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  display: flex;
  background-size: contain;
  padding: 25px;
  justify-content: space-evenly;
  gap: 10px;
  width: min(700px, 100%);
  margin: auto;
  transition: var(--base-transition);
  border-radius: 6px;
}
.testominials {
  width: min(800px, 100%);
  margin: auto;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.rightOpinion p {
  margin-top: 20px;
}
.rightOpinion {
  width: min(400px, 100%);
  /* margin-left: auto; */
}

.leftOpinion h6 {
  color: var(--secondary-color);
  font-weight: 400;
}

/* .opinion:hover {
  box-shadow: 1px 2px 24px -2px rgba(128, 128, 128, 0.342);
} */

@media (max-width: 592px) {
  .features .banner_content {
    padding: 5px 10px 10px !important;
  }
  .banner .left_banner h1 {
    font-size: 29px;
    line-height: 64px;
    font-weight: 700;
  }
  .left_banner_images .image > img {
    width: 120px;
  }

  .rate {
    margin-top: 23px;
  }

  .rate p {
    font-size: 13px !important;
  }
  .banner_content {
    padding: 100px 13px 40px;
  }
  .breadCrumb h1 {
    font-size: 22px;
  }
}
