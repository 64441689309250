.tweetsunits {
  padding: 10px;
  /* color: var(--main-color); */
}

.tweetsunits>h3>div>h3 {
  color: black;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
}

.tweetsunits>h3>div span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
}

.tweetsunits>h3 {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: start;
  flex-direction: row-reverse;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  /* justify-content: space-between; */
}

.tweetsunits>h3 svg {
  cursor: pointer;
  color: var(--main-color);
}

.tweetsunits p {
  margin: 10px 0px;
  color: #828282;
  font-size: 14px;
}