.banner {
  background-color: var(--main-color);
  min-height: 17vh;
  border-radius: 0px 0px 20px 20px;
  color: white;
  padding: 10px;
}

.banner>h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner>h3 svg {
  cursor: pointer;
}

.banner .re_keyword {
  flex-wrap: wrap;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.banner .re_keyword .left,
.banner .re_keyword .right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.banner .re_keyword .left>*,
.banner .re_keyword .right>* {
  margin: 0px;
}

.banner .re_keyword img {
  width: 13px;
}