div#whyus .whyDesc {
  padding: 10px;
}

div#whyus {
  overflow: hidden;
}

div#whyus {
  width: min(900px, 100%);
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-items: center;
  align-content: center;
  align-items: center;
}
.whyDesc img {
  width: 30px;
}

div#whyus .whyDesc {
  padding: 10px;
  box-shadow: 1px 2px 24px -2px #8080801c;
  margin: 10px 0;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

div#whyus > div > img {
  height: auto;
  max-width: 270px;
}

.whyDescIcon {
  padding: 10px 0;
}
