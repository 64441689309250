.preview {
  width: min(800px, 100%);
  min-height: 50vh;
  margin: 22px auto 10px;
  background: url("../../Assets/images/video-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
}

.video-tour-item {
  align-items: center;
  padding: 13px;
  border-radius: 52px;
  background: #fff;
  position: relative;
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  width: min(297px, 100%);
  min-height: 90px;
}

.video-tour-item .icon {
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: var(--main-color);
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: var(--base-transition);
}

.video-tour-item .icon:hover {
  color: var(--main-color);
  background: #fff;
}

.video-tour-item .icon svg {
  font-size: 20px;
  width: 70%;
}

.video-tour-item .cont {
  width: calc(100% - 78px);
  padding: 0 25px 0 20px;
  color: var(--secondary-color);
  font-size: 14px;
}

.preview_video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 123432;
  animation: previewFromBootom 1s linear forwards;
}

.preview_video .overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.388) !important;
  z-index: 8;
}

.preview_video video {
  position: absolute;
  top: 50%;
  width: min(900px, 100%);
  left: 50%;
  z-index: 11332;
  translate: -50% -200%;
  border-radius: 19px;
  animation: previewVideoFromBootom 1s 1.1s linear forwards;
}

@keyframes previewFromBootom {
  0% {
    left: 130%;
  }
  100% {
    left: 0;
  }
}

@keyframes previewVideoFromBootom {
  0% {
    translate: -50% -200%;
  }
  100% {
    translate: -50% -50%;
  }
}

.swiper-3d .swiper-slide-shadow-left{
  background-image: none !important;
}