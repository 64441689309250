.tweetscourses h3 {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
}

.tweetscourses h3 h4 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
}