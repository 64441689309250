.question {
  font-size: 13px;
}

.question>* {
  margin: 0px;
}

.question img {
  margin: 10px auto;
}