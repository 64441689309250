.banner_menu {
  background-color: var(--main-color);
  min-height: 13vh;
  border-radius: 0px 0px 20px 20px;
  color: white;
  padding: 10px;
}

.banner_menu h4 {
  font-size: 13px;
  font-weight: 400;
  color: white;
  margin-bottom: 10px;
}

.banner_menu>h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_menu>h3 svg {
  cursor: pointer;
}

.banner_menu .re_keyword {
  flex-wrap: wrap;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.banner_menu .re_keyword .left,
.banner_menu .re_keyword .right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.banner_menu .re_keyword .left>*,
.banner_menu .re_keyword .right>* {
  margin: 0px;
}

.banner_menu .re_keyword img {
  width: 13px;
}

.units_page h4 {
  font-size: 14px !important;
  margin-top: 10px;
}

.device h3 {
  font-size: 14px !important;
  margin-left: 4px;
}