@media (max-width:18rem) {
  .flashcard {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    background-color: var(--second-color);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
  }

  .flashcard .left {
    width: 100px;
  }

  .flashcard .right {
    width: calc(100% - 150px);
    flex: 1;
  }

  .flashcard .left img {
    max-width: 100%;
    border-radius: 10px;
  }

  .flashcard .right h3 {
    font-size: 13px;
    color: var(--main-color);
    background: rgba(217, 217, 217, 1);
    padding: 4px 10px;
    margin-bottom: 4px;
    width: fit-content;
    text-align: center;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flashcard .right h2 {
    font-size: 14px;
    font-weight: 600;
  }

  .flashcard .right p {
    font-size: 13px;
    margin: 5px 0px;
  }

  /* .flashcard .right p span {
    display: flex;
    align-items: center;
    gap: 4px;
  } */
}