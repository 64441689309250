.websiteContainer > header {
  display: flex;
  z-index: 14512;
  position: fixed;
  transition: var(--base-transition);
}
.websiteContainer > header > nav ul {
  margin: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: center;
}

.websiteContainer > header > nav .header_logo img {
  height: 90px;
}
.websiteContainer > header > nav {
  width: 100%;
  height: 13vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: var(--min-width);
  margin: auto;
  padding: 10px;
}
.websiteContainer header {
  width: 100%;
}
.websiteContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.websiteContainer > header > nav ul li a {
  text-decoration: none;
  color: black;
  font-size: var(--header-font-size);
  padding: 6px 10px !important;
  transition: var(--base-transition);
}

.websiteContainer > header > nav ul li:hover a {
  color: var(--main-color);
}

.downloadModal a {
  color: white;
  text-decoration: none;
  background: var(--main-color);
  padding: 10px;
  border-radius: 10px;
  transition: 0.7s ease-in-out;
  scale: 0.4;
  opacity: 0.4;
}
.downloadModal {
  position: absolute;
  /* background: var(--secondary-color); */
  color: white;
  width: 100%;
  padding: 10px;
  top: 120%;
  border-radius: 2px;
  transition: 0.7s ease-in-out;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  scale: 0;
  opacity: 0;
}

.header_download {
  z-index: 123112;
}
.header_download:hover .downloadModal,
.header_download:hover .downloadModal a {
  scale: 1;
  opacity: 1;
}

.websiteContainer header.sticky {
  background-color: white;
}

.menu {
  display: none;
}

nav > .header_download {
  display: none;
}

@media (max-width: 992px) {
  .websiteContainer > header > nav ul {
    position: fixed;
    flex-direction: column;
    top: 0;
    left: -100%;
    padding: 110px 10px;
    background: white;
    height: 100%;
    gap: 20px;
    align-items: flex-start;
    width: 300px;
    transition: 0.7s ease-in-out;
  }
  .websiteContainer > header > nav ul.show {
    left: 0;
  }
  .websiteContainer > header > nav ul .header_download {
    margin-top: auto;
  }
  .websiteContainer > header > nav {
    justify-content: space-between !important;
  }
  .header_download .downloadModal,
  .header_download .downloadModal a {
    scale: 1;
    opacity: 1;
  }
  .menu {
    display: flex;
    cursor: pointer;
  }
}

.header_download button {
  font-size: 18px;
}
