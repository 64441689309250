.flashtopic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0px;
  background-color: var(--second-color);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.flashtopic .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flashtopic .left>p {
  background-color: rgba(217, 217, 217, 1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  border-radius: 50%;
}

.flashtopic .left .det h6 {
  font-size: 14px;
  font-weight: 500;
}

.flashtopic .left .det p {
  color: #828282;
  font-size: 13px;
}