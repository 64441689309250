.banner {
  width: 100%;
  background: linear-gradient(
    117deg,
    rgb(60 60 60 / 26%) 0%,
    rgb(234 74 95 / 48%) 87%
  );
  display: flex;
}

.banner_content {
  width: var(--min-width);
  min-height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 40px 40px;
  overflow: hidden;
}

.banner .left_banner h6 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 19px;
  color: var(--main-color);
}

.banner .left_banner,
.banner_content > div:first-child {
  display: flex;
  gap: 7px;
  width: min(650px, 100%);
  flex-direction: column;
}

.banner .left_banner h1 {
  font-size: 44px;
  line-height: 64px;
  font-weight: 700;
}

.banner .left_banner p {
  font-size: 20px;
  font-weight: 400;
}

.left_banner_images img {
  width: 200px;
  cursor: pointer;
}

.left_banner_images {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 19px;
  margin-top: 35px;
}

.rate img {
  width: 16px;
  margin-right: 3px;
}

.rate {
  margin-top: 56px;
  font-weight: 900 !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 10px;
}

.rate h3 {
  font-size: 32px;
  font-weight: 700;
  color: var(--main-color);
}

.rate p {
  font-size: 16px;
  font-weight: 400;
}

.right_banner {
  display: none;
}

.right_banner img {
  border-radius: 7px;
  width: 250px;
  rotate: 20deg;
  right: -10px;
}
.right_banner {
  rotate: -11deg;
  /* overflow: hidden; */
  min-height: 100%;
  border-radius: 5px;
  margin-left: 60px;
}

@media (min-width: 1000px) {
  .banner .left_banner h1 {
    font-size: 40px;
  }
  .banner .left_banner h6,
  .banner .left_banner > p {
    font-size: 24px;
  }
  .rate h3 {
    font-size: 54px;
  }
  .right_banner {
    display: flex;
  }
}
